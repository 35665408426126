body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

blockquote {
  background: #f9f9f9;
  border-left: 0.5rem solid #ccc;
  margin: 0.5rem;
  padding: 0.5rem;
  quotes: '“' '”';
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4rem;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

iframe {
  border: 0;
}
